import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  Content,
  PaginatedGrid,
  componentIterator,
} from "@bluefin/components";
import { Grid, Embed } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              componentIdentifier: "background",
              numToSelect: 1,
            })}
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <Grid
            className={"about-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column width={6}>
              <Content className={"about-content"} textAlign={"left"}>
                <div className={"home-hero-video-container"}>
                  <Embed
                    url={
                      "https://player.vimeo.com/video/970710925?background=1"
                    }
                    autoplay={true}
                    defaultActive={true}
                    defaultActive={true}
                    className={"home-hero-video"}
                  />
                  <div className={"hero-logo"} />
                </div>
                <Content.Markup
                  width={16}
                  isMarkdown={false}
                  content={
                    <div>
                      <p>
                        We believe that good food should be accompanied by great
                        company and a fun environment, which is why our decor is
                        lively and colorful, and our music will transport you to
                        another world.
                      </p>
                      <p>
                        At Cosmic Burrito, our friendly staff will ensure you
                        have a memorable experience, whether you're catching up
                        with friends or celebrating a special occasion. We
                        invite you to come see for yourself why we're the talk
                        of the town! Whether you're a foodie or just looking for
                        a fun night out, Cosmic Burrito is the perfect
                        destination.
                      </p>
                    </div>
                  }
                />
              </Content>
            </Grid.Column>
            <Grid.Column
              className={"about-image-gallery-container"}
              width={10}
              textAlign={"left"}
            >
              <PaginatedGrid
                rows={3}
                className={"image-gallery"}
                mobile={{ columns: 2, rows: 4, stackable: false }}
                items={componentIterator({
                  iterator: getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "ImageCollection",
                  }),
                  component: (
                    <Image
                      background={true}
                      style={{ paddingTop: "100%", height: "100%" }}
                    />
                  ),
                  propMap: { src: "__all__" },
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
